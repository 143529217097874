<template>
  <div class="container">
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
    <div class="login-box">
      <div class="title columns has-text-centered has-text-white">
        <div class="column is-12 is-size-2">{{ title }}</div>
      </div>
      <div class="title columns has-text-centered has-text-white">
        <div class="column is-12 is-size-4">logowanie</div>
      </div>
      <form @submit.prevent="signin">
        <div class="container content is-centered">
          <div class="is-flex is-justify-content-center">
            <figure class="image is-256x256">
              <b-image
                :src="require(`@/assets/img/home/icons/${icon}`)"
              ></b-image>
            </figure>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-6">
              <strong>Email</strong>
            </div>
            <div class="column is-6">
              <b-field>
                <b-input v-model="form.email" rounded></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-6">
              <strong>Hasło</strong>
            </div>
            <div class="column is-6">
              <b-field>
                <b-input
                  v-model="form.password"
                  rounded
                  type="password"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns is-centered">
            <b-button
              type="is-primary"
              rounded
              size="is-medium"
              class="button is-centered"
              @click="login"
              >ZALOGUJ</b-button
            >
          </div>
          <div class="column is-full has-text-centered is-size-6">
            <div class="block">
              Nie masz jeszcze konta?
              <router-link :to="registrationLink">
                Zarejestruj się
              </router-link>
            </div>
          </div>
          <div class="column is-full has-text-centered is-size-6">
            <div class="block">
              Nie pamiętasz hasła?
              <router-link
                :to="{
                  name: 'RestorePassword',
                  params: {
                    type: $route.name == 'ClientLogin' ? 'user' : 'company',
                  },
                }"
              >
                Przypomnij hasło
              </router-link>
            </div>
          </div>
          <b-message type="is-danger" class="center" v-if="status"
            >Błąd logowania {{ content }}</b-message
          >
        </div>
      </form>
    </div>
    <!--  <div class="card-footer">
                <div class="card-footer-item is-size-6">
                  <router-link :to="{ name: 'RestorePassword' }">
                    Nie pamiętam hasła
                  </router-link>
                </div>
              </div> -->
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
//import ValidatableEmail from "@/components/form/ValidatableEmail";
//import ValidatablePassword from "@/components/form/ValidatablePassword";

export default {
  components: {
    //"validatable-email": ValidatableEmail,
    //"validatable-password": ValidatablePassword,
  },

  props: {
    title: String,
    icon: String,
    registrationLink: Object,
  },

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      status: false,
      content: "",
      isLoading: false,
      isFullPage: true,
      type: this.title == "Wirtualne biuro - logowanie" ? true : false,
    };
  },

  methods: {
    login() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.response = {
          status: false,
          content: "Niepoprawnie wypełniony formularz",
        };
        this.showError = true;
        return;
      }
      let payload = {
        username: this.form.email,
        password: this.form.password,
      };
      this.isLoading = true;
      if (this.type) {
        // Logowanie klient biznesowy
        this.$store
          .dispatch("auth/loginCompany", payload)
          .then((response) => {
            let hasErrors = true;
            if (Array.isArray(response)) {
              hasErrors = response.some((res) => res.status !== 200);
            } else {
              hasErrors = response.status != 200;
            }
            //console.log("hasErrors", hasErrors);
            //console.log("res", response);

            if (hasErrors) {
              this.response = {
                status: response.data.success,
                content: "Niepoprawne dane logowania",
              };
            } else {
              //console.log("Zalogowano", response);
              this.isLoading = false;
              /* this.$store.dispatch(
                "auth/loadCompanyData",
                response.data.content.id
              ); */
              //console.log("Zalogowano");
              this.$router.push({ name: "CompanyOrders" });
            }
          })
          .catch(() => {
            this.response = {
              status: false,
              content: "Niepoprawne dane logowania",
            };
            this.isLoading = false;
          });
      } else {
        // Logowanie klient zwykły
        this.$store
          .dispatch("auth/login", payload)
          .then((response) => {
            //console.log("res", response);
            if (response.status != 200) {
              this.response = {
                status: response.data.success,
                content: "Niepoprawne dane logowania",
              };
              this.showError = true;
            } else {
              this.isLoading = false;
              this.$store.dispatch(
                "auth/loadUserData",
                response.data.content.id
              );
              this.$router.push({ name: "Cars" });
            }
          })
          .catch(() => {
            this.response = {
              status: false,
              content: "Niepoprawne dane logowania",
            };
            this.showError = true;
            this.isLoading = false;
          });
      }
    },

    hasErrors(vFormField) {
      return vFormField.$invalid && vFormField.$error;
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },

      password: {
        required,
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.login-box {
  position: relative;
  min-width: 300px;
  top: 5rem;
}

figure {
  top: 0.5rem;
}
.bg-image {
  background-image: url("./../../assets/img/background/bck2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
  padding: 0;
  padding-top: 1rem;
  min-height: 50vw;

  hr {
    width: 90vw;
    height: 0.5rem;
    position: relative;
    left: 5vw;
  }

  .content {
    min-width: 350px;
    min-height: 45vh;
    background-color: #ffffffc9;
    border-radius: 15px;
  }

  .header {
    position: relative;
    color: $primary;
    font-weight: 400;
    right: 1rem;
  }

  .container {
    .columns {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  button {
    width: 25rem;
  }
}
</style>